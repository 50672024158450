export function Footer() { 
    return(
        <>
            <footer className=" bg-black tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-8 tw-bg-gray-800 tw-text-white tw-text-center tw-mb-4">
                {/* Logo Section */}
                <img src="../logo.svg" alt="logo" className="tw-max-w-[120px] tw-mb-4" />
                {/* Description Section */}
                <div className="tw-text-sm tw-w-full tw-max-w-[450px] tw-text-center tw-mt-6 tw-mb-6">
                    Echo AI is your all-in-one productivity assistant, offering seamless task management, real-time reminders, and financial tracking. With support for both voice commands and text input, Echo AI keeps you organized and efficient whether you’re at home, at work, or on the go.
                </div>
                <div className="tw-flex tw-gap-6 max-md:tw-text-sm tw-mt-8 tw-mb-8 mb-4">
                    <a href="/#dashboard" className="footer-link tw-text-bg hover:tw-text-gray-400">HOME</a>
                    <a href="/#features" className="footer-link tw-text-bg hover:tw-text-gray-400">Echo AI Features</a>
                    <a href="/#qa" className="footer-link tw-text-bg hover:tw-text-gray-400">FAQ</a>
                    <a href="/privacy" className="footer-link tw-text-bg hover:tw-text-gray-400">Privacy policy</a>
                </div>
            </footer>
        </>
    )
}
