import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export function DeleteConfirm({state, setState}) {

    const [ verifyData, setVerifyData] = useState({
        email: state.email,
        code:'',
        token:''
    });

    useEffect( () => {
        const deleteAccount = async () => {
            try {
                const response = await fetch("https://assistant.doppelmate.com/v1.0/profile", {
                    method: "DELETE",
                    headers:{
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${verifyData?.token}`
                    }
                } )
                if( response.ok && response.status >= 200 && response.status < 300 ){
                    toast.success(
                        response?.data?.message||'Account has been deleted.'
                    )
                } else {
                    toast.warning(
                        response?.data?.message||'Unable to complete your request.'
                    )
                }
            } catch(e){
                toast.error(
                    e.response?.data?.message||'Something went wrong. Please try again later'
                )
            }
        }
        if( typeof verifyData.token === 'string' && verifyData.token.length ){
            deleteAccount()
        }
    }, [verifyData?.token])

    const handleBttnBack = () => {
        setState({ ...state, step: 1 });
    };
    
    const encodeBase64 = (str) => {
        return btoa(str);
    };
    
    const confirmDeleteCode = async () => {
        try {
            const newCode = verifyData.code.replace("-", "");
        
            const response = await fetch("https://assistant.doppelmate.com/v1.0/auth/verify-email-mfa", {
                method: "POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    email: verifyData.email,
                    code: encodeBase64(newCode)
                })
            } )
            if( response.ok && response.status >= 200 && response.status < 300 ){
                const payload = await response.json()
                setVerifyData({ ...verifyData, token: payload?.tokenData?.accessToken })
            } else {
                toast.info(
                    response?.data?.message||'Something went wrong. Please try again later'
                )
            }
        } catch(e){
            toast.error(
                e.response?.data?.message||'Something went wrong. Please try again later'
            )
        }
    }

    const handleInputChange = (e) => {
        setVerifyData({ ...verifyData, code: e.target.value});
    };

    const handleConfirmCode = async () => {
        confirmDeleteCode();
    };

    return(
        <>
        <section className="bg-white">
            <div className="flex items-center justify-center px-4 py-10 bg-white sm:px-6 lg:px-8 sm:py-16 lg:py-16">
                <div className="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto　border-stone mx-auto max-w-lg rounded-lg border bg-stone-100 p-4 shadow-lg sm:p-6 lg:p-8 mt-16">
                    <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl">Confirm Delete Account</h2>

                        <div className="space-y-5 mt-8">
                            <div>
                                <label htmlFor="code" className="text-base font-medium text-gray-900"> Confirmation Code </label>
                                <div className="mt-2.5">
                                    <input
                                        type="code"
                                        name="code"
                                        id="code"
                                        placeholder="Enter OTP Code"
                                        className="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                                        onChange={handleInputChange}
                                        onInput={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div>
                    
                            </div>

                            <div class="flex justify-between">
                                <button type="button" onClick={handleBttnBack} className="px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700">Back</button>
                                <button type="submit" onClick={handleConfirmCode} className="px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700">Delete Account</button>
                            </div>
                            <p>
                                    <small>
                                        <sup>*</sup>Check your spam folder in email if you didn't recieve a code
                                    </small>
                            </p>
                            
                        </div>
                </div>
            </div>
        </section>
        </>
    )
}