import { useEffect, useState } from "react"
import { ToastContainer, cssTransition } from "react-toastify"

export function Header() {
    const bounce = cssTransition({
      enter: "animate__animated animate__bounceIn",
      exit: "animate__animated animate__bounceOut"
    });

    const RESPONSIVE_WIDTH = 1024
    const [ isHeaderCollapsed, setHeaderCollapse ] = useState( window.innerWidth < RESPONSIVE_WIDTH )
    const collapseBtn = document.getElementById("collapse-btn")
    const collapseHeaderItems = document.getElementById("collapsed-header-items")

    const responsive = () => {
        if (window.innerWidth > RESPONSIVE_WIDTH) {
            if( collapseHeaderItems && collapseHeaderItems.style ){
                collapseHeaderItems.style.width = ""
            }
        } else {
            setHeaderCollapse(true)
        }
    }

    const onHeaderClickOutside = (e) => {
        if (!collapseHeaderItems.contains(e.target)) {
            toggleHeader()
        }
    }

    const toggleHeader = () => {
        if (isHeaderCollapsed) {
            // collapseHeaderItems.classList.remove("max-md:tw-opacity-0")
            collapseHeaderItems.classList.add("opacity-100",)
            collapseHeaderItems.style.width = "60vw"
            collapseBtn.classList.remove("bi-list")
            collapseBtn.classList.add("bi-x", "max-lg:tw-fixed")
            setHeaderCollapse(false)

            setTimeout(() => window.addEventListener("click", onHeaderClickOutside), 1)

        } else {
            collapseHeaderItems.classList.remove("opacity-100")
            collapseHeaderItems.style.width = "0vw"
            collapseBtn.classList.remove("bi-x", "max-lg:tw-fixed")
            collapseBtn.classList.add("bi-list")
            setHeaderCollapse(true)
            window.removeEventListener("click", onHeaderClickOutside)

        }
    }

    useEffect( () => {
        window.addEventListener("resize", responsive)
    })

    return(
        <>
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={bounce}
            />
            <header className="bg-black tw-max-w-lg:tw-px-4 tw-max-w-lg:tw-mr-auto fixed tw-top-0 tw-z-20 tw-flex tw-h-[60px] tw-w-full tw-bg-opacity-0 tw-px-[5%] lg:tw-justify-around">
                <a className="tw-h-[180px] tw-w-[180px] tw-p-[4px]" href="/">
                    <img src="../logo.svg" alt="logo" className="tw-object tw-h-full tw-w-full" />
                </a>
                <div className="collapsible-header animated-collapse max-lg:tw-shadow-md" id="collapsed-header-items">
                    <div className="tw-flex tw-h-full tw-w-max tw-gap-5 tw-text-base max-lg:tw-mt-[30px] max-lg:tw-flex-col max-lg:tw-place-items-end max-lg:tw-gap-5 lg:tw-mx-auto lg:tw-place-items-center">
                    <a className="header-links" href="/#dashboard"> Home </a>
                    <a className="header-links" href="/#features">Features</a>
                    <a className="header-links" href="/#qa">FAQ</a>
                    </div>
                    <div className="tw-mx-4 tw-flex tw-place-items-center tw-gap-[20px] tw-text-base max-md:tw-w-full max-md:tw-flex-col max-md:tw-place-content-center">
                    <a href="https://echoai.page.link/pa" target="_blank" rel="noreferrer" aria-label="signup" className="tw-rounded-full tw-bg-white tw-px-3 tw-py-2 tw-text-black tw-transition-transform tw-duration-[0.3s] hover:tw-translate-x-2">
                        <span className="pr-2">Get Echo AI</span>
                        <i className="bi bi-download" />
                    </a>
                    </div>
                </div>
                <button className="bi bi-list tw-absolute tw-right-3 tw-top-3 tw-z-50 tw-text-3xl tw-text-white lg:tw-hidden" onClick={toggleHeader} aria-label="menu" id="collapse-btn" />
            </header>
        </>
    )
 }

