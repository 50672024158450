import './App.css';
import { Routes, Route } from 'react-router-dom';
import './CSS/tailwind-build.css';
import './CSS/tailwind.css';
import './CSS/common.css';

import { Home } from './Pages/home';
import { Privacy } from './Pages/privacy';
import { Delete } from './Pages/delete';
import { ToastContainer, cssTransition } from 'react-toastify';

function App() {
  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut"
  });

  return (
    <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/delete" element={<Delete />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      transition={bounce}
      />
    </>
  );
}

export default App;
