import { toast } from 'react-toastify';
import { useState } from 'react';

export function DeleteRequest({setState}) {

    const [loginData, setLoginData] = useState({
        email: "",
    });

    const requestDeleteCode = async () => {
        try {
            const response = await fetch("https://assistant.doppelmate.com/v1.0/auth/get-email-mfa", {
                method: "POST",
                headers:{
                    "Content-Type":"application/json",
                },
                body: JSON.stringify(loginData)
            } )
            if( response.ok && response.status >= 200 && response.status < 300 ){
                setState({email:loginData.email, step:2 })
            } else {
                toast.error(
                    response?.data?.message||'Something went wrong. Please try again later'
                )
            }
        } catch(e){
            toast.error(
                e.response?.data?.message||'Something went wrong. Please try again later'
            )
        }
    }

    const handleInputChange = (e) => {
        setLoginData({ email: e.target.value});
    };

    const handleRequestCode = async () => {
        requestDeleteCode();
    };

    return(
        <>
        <section className="bg-white">
            <div className="flex items-center justify-center px-4 py-10 bg-white sm:px-6 lg:px-8 sm:py-16 lg:py-16">
                <div className="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto　border-stone mx-auto max-w-lg rounded-lg border bg-stone-100 p-4 shadow-lg sm:p-6 lg:p-8 mt-16">
                    <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl">Delete Account Information</h2>
                    <p className="mt-2 text-base text-gray-600">* All your subscriptions will be cancelled </p>
                    <p className="mt-2 text-base text-gray-600">* All your information will be removed permanently</p>

                        <div className="space-y-5 mt-8">
                            <div>
                                <label htmlFor="email" className="text-base font-medium text-gray-900"> Email address </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter email to get started"
                                        className="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                                        onChange={handleInputChange}
                                        onInput={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div>
                    
                            </div>

                            <div>
                                <button type="submit" onClick={handleRequestCode} className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700">Request Account Delete</button>
                            </div>
                        </div>
                </div>
            </div>
        </section>
        </>
    )
}