import { Header} from '../Components/Header';
import { Body} from '../Components/Body';
import { Footer} from '../Components/Footer';


export function Home() {
    return( 
        <>
            <Header/>
            <Body/>
            <Footer/>
        </>
    )
}