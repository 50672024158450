import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';
import { DeleteRequest } from '../Components/DeleteRequest';
import { DeleteConfirm } from '../Components/DeleteConfirm';
import { useState } from 'react';

export function Delete() {

    const [loginPageData, setLoginPageData] = useState({
        step: 1,
        email: "",
    });
    return (
        <>
            <Header />
            {loginPageData.step === 1 ? (
                <DeleteRequest
                    setState={setLoginPageData}
                />
            ) : (
                <DeleteConfirm
                    state={loginPageData}
                    setState={setLoginPageData}
                />
            )}
            <Footer />

        </>
    )
}