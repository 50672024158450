import { useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function Body() {
    useLayoutEffect(() => {
        console.log("EFFECT")
        /**
         * Animations
         */
        gsap.registerPlugin(ScrollTrigger)

        gsap.to(".reveal-up", {
            opacity: 0,
            y: "100%",
        })

        // gsap.to("#dashboard", {
        //     boxShadow: "0px 15px 25px -5px #7e22ceaa",
        //     duration: 0.3,
        //     scrollTrigger: {
        //         trigger: "#hero-section",
        //         start: "60% 60%",
        //         end: "80% 80%",
        //         // markers: true
        //     }

        // })

        // straightens the slanting image
        gsap.to("#dashboard", {
            scale: 1,
            translateY: 0,
            // translateY: "0%",
            rotateX: "0deg",
            scrollTrigger: {
                trigger: "#hero-section",
                start: "top 80%",
                end: "bottom bottom",
                scrub: 1,
                // markers: true,
            }
        })

        const sections = gsap.utils.toArray("section")

        sections.forEach((sec) => {
            const revealUptimeline = gsap.timeline({
                paused: true,
                scrollTrigger: {
                    trigger: sec,
                    start: "10% 80%", // top of trigger hits the top of viewport
                    end: "20% 90%",
                    // markers: true,
                    // scrub: 1,
                }
            })

            if( sec.querySelectorAll(".reveal-up").length > 0 ){
                revealUptimeline.to(sec.querySelectorAll(".reveal-up"), {
                    opacity: 1,
                    duration: 0.8,
                    y: "0%",
                    stagger: 0.2,
                })
            }
        })

    }, []);

    const toggleFaqAccordion = (event) => {
        event.preventDefault()
        let target = event.target
        if( !target.classList.contains("faq-accordion") ){
            target.closest('.faq-accordion').classList.toggle('active')
        } else {
            target.classList.toggle('active')
        }
    }

    return (
        <>
            <section className="hero-section bg-black tw-relative tw-flex tw-min-h-[100vh] tw-w-full tw-max-w-[100vw] tw-flex-col tw-overflow-hidden max-md:tw-mt-[50px]" id="hero-section">
                <div className="tw-flex tw-h-full tw-min-h-[100vh] tw-w-full tw-flex-col tw-place-content-center tw-gap-6 tw-p-[5%] max-xl:tw-place-items-center max-lg:tw-p-4">
                    <div className="tw-flex tw-flex-col tw-place-content-center tw-items-center">
                        <div className="reveal-up gradient-text tw-text-center tw-text-6xl tw-font-semibold tw-uppercase tw-leading-[80px] max-lg:tw-text-4xl max-md:tw-leading-snug">
                            <span> Never forget</span>
                            <br />
                            <span>  a task again with Echo AI. 🔍 </span>
                        </div>
                    </div>
                    <div className="reveal-up tw-relative tw-mt-8 tw-flex tw-w-full tw-place-content-center tw-place-items-center" id="dashboard-container">
                        <div className="tw-relative tw-max-w-[800px] tw-max-h-[800px] tw-overflow-hidden tw-rounded-xl tw-bg-transparent max-md:tw-max-w-full" id="dashboard">
                            <img src="./assets/images/home/phone.png" alt="dashboard" className=" tw-w-full tw-object-contain tw-opacity-90 " />
                        </div>
                        <div className="hero-img-bg-grad tw-absolute tw-left-[50%] tw--translate-x-[50%] tw-top-5 tw-h-[200px] tw-w-[200px]" />
                    </div>
                    <div className="tw-flex tw-flex-col tw-w-full tw-place-items-center tw-place-content-center">
                        <div className="reveal-up tw-mt-10 tw-max-w-[450px] tw-p-2 tw-text-center tw-text-gray-300 max-lg:tw-max-w-full">
                            Your 24/7 personal assistant. Manage tasks, set reminders, and boost your productivity effortlessly. 🚀✨
                        </div>
                        <div className="reveal-up tw-mt-10 tw-flex tw-place-items-center tw-gap-4">
                            <a href="https://echoai.page.link/pa" rel="noreferrer" target="_blank" className="btn tw-bg-[#7E40F8] tw-shadow-lg tw-transition-transform tw-duration-[0.3s] hover:tw-scale-x-[1.03]" >
                                Download on App Store
                            </a>
                            <a href="https://echoai.page.link/pa" rel="noreferrer" target="_blank" className="btn tw-bg-[#7E40F8] tw-shadow-lg tw-transition-transform tw-duration-[0.3s] hover:tw-scale-x-[1.03]" >
                                Download on Play Store
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-black tw-relative tw-pt-10 tw-flex tw-min-h-[100vh] tw-w-full tw-max-w-[100vw] tw-flex-col tw-place-items-center lg:tw-p-6">
                <h2 className="reveal-up gradient-text tw-text-4xl max-md:tw-text-3xl">Unlock the Power of Echo AI’s Key Features</h2>
                <div className="reveal-up tw-mt-[5%] tw-flex tw-h-full tw-w-full tw-place-content-center tw-gap-8 tw-p-4 max-lg:tw-max-w-full max-lg:tw-flex-col">
                    <div className="tw-flex tw-flex-col tw-gap-[200px] tw-h-full tw-max-w-[50%] max-lg:tw-max-w-full tw-px-[10%] max-lg:tw-px-4 max-lg:tw-gap-16 max-lg:tw-w-full lg:tw-top-[20%]">
                        <div className="tw-flex tw-gap-6">
                            <div>
                                {/* <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                    <path d="M3 4.5C3 3.67157 3.67157 3 4.5 3H6.5C7.32843 3 8 3.67157 8 4.5V6.5C8 7.32843 7.32843 8 6.5 8H4.5C3.67157 8 3 7.32843 3 6.5V4.5Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M3 17.5C3 16.6716 3.67157 16 4.5 16H6.5C7.32843 16 8 16.6716 8 17.5V19.5C8 20.3284 7.32843 21 6.5 21H4.5C3.67157 21 3 20.3284 3 19.5V17.5Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M8.00002 18.5H21M16 5.5H8.00002M16.3235 7.67649L7.64868 16.3513" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 4.5C16 3.67157 16.6716 3 17.5 3H19.5C20.3284 3 21 3.67157 21 4.5V6.5C21 7.32843 20.3284 8 19.5 8H17.5C16.6716 8 16 7.32843 16 6.5V4.5Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M18 21L19.3883 20.0537C20.4628 19.3213 21 18.9551 21 18.5C21 18.0449 20.4628 17.6787 19.3883 16.9463L18 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </div> */}
                            </div>
                            <h3 className="text-gray-50 tw-text-4xl tw-font-medium max-lg:tw-text-2xl max-lg:tw-font-normal">
                                Your reliable memory extension for a more organized life. 🗂️🔍
                            </h3>
                        </div>
                        <div className="tw-flex tw-gap-6">
                            {/* <div className="tw-w-[80px] tw-h-[80px] tw-min-w-[80px] tw-min-h-[80px] max-lg:tw-min-h-[60px] max-lg:tw-min-w-[60px] max-lg:tw-h-[60px] max-lg:tw-w-[60px]">
                                <img src="./assets/images/icons/lighting.png" alt="unlock" className="tw-object-contain tw-w-full tw-h-full" />
                            </div> */}
                            <h3 className="text-white tw-text-4xl tw-font-medium max-lg:tw-text-2xl max-lg:tw-font-normal">
                                Stay organized effortlessly with Echo AI. From reminders to task management, we've got you covered! 📅 👌
                            </h3>
                        </div>
                        <div className="tw-flex tw-gap-6">
                            {/* <div className="tw-w-[80px] tw-h-[80px] tw-min-w-[80px] tw-min-h-[80px] max-lg:tw-min-h-[60px] max-lg:tw-min-w-[60px] max-lg:tw-h-[60px] max-lg:tw-w-[60px]">
                                <img src="./assets/images/icons/credit-card.png" alt="unlock" className="tw-object-contain tw-w-full tw-h-full" />
                            </div> */}
                            <h3 className="text-white tw-text-4xl tw-font-medium max-lg:tw-text-2xl max-lg:tw-font-normal">
                                Echo AI: The smarter way to manage your day. Speak or text to set reminders and stay on top of your schedule. ⏰ 📅
                            </h3>
                        </div>
                        <div className="tw-flex tw-gap-6">
                            {/* <div className="tw-w-[80px] tw-h-[80px] tw-min-w-[80px] tw-min-h-[80px] max-lg:tw-min-h-[60px] max-lg:tw-min-w-[60px] max-lg:tw-h-[60px] max-lg:tw-w-[60px]">
                                <img src="./assets/images/icons/music.png" alt="unlock" className="tw-object-contain tw-w-full tw-h-full" />
                            </div> */}
                            <h3 className="text-white tw-text-4xl tw-font-medium max-lg:tw-text-2xl max-lg:tw-font-normal">
                                Boost your efficiency with Echo AI. Instant reminders and task tracking at your fingertips. 🧠💡
                            </h3>
                        </div>
                        <div className="tw-flex tw-gap-6">
                            {/* <div className="tw-w-[80px] tw-h-[80px] tw-min-w-[80px] tw-min-h-[80px] max-lg:tw-min-h-[60px] max-lg:tw-min-w-[60px] max-lg:tw-h-[60px] max-lg:tw-w-[60px]">
                                <img src="./assets/images/icons/unlock.png" alt="unlock" className="tw-object-contain tw-w-full tw-h-full" />
                            </div> */}
                            <h3 className="text-white tw-text-4xl tw-font-medium max-lg:tw-text-2xl max-lg:tw-font-normal">
                                Echo AI keeps you on track. Speak or type to never miss an appointment or deadline. 📋✨
                            </h3>
                        </div>
                       
                    </div>
                    <div className="tw-relative tw-flex tw-max-w-[30%] max-lg:tw-max-w-full tw-flex-col tw-place-items-start tw-gap-4  tw-p-2 max-lg:tw-place-items-center max-lg:tw-place-content-center max-lg:tw-w-full">
                        <div className="tw-top-20 lg:tw-sticky tw-max-h-[800px] tw-h-full tw-max-w-[850px] max-lg:tw-max-h-fit max-lg:tw-max-w-[320px] tw-overflow-hidden tw-rounded-lg">
                            <img src="./assets/images/home/phone3.png" alt="phone" className="tw-h-full tw-w-full tw-object-contain" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="bg-black tw-relative tw-flex tw-min-h-[80vh] tw-w-full tw-max-w-[100vw] tw-flex-col tw-place-content-center tw-place-items-center tw-overflow-hidden tw-p-6">
                <div className="tw-mt-8 tw-flex tw-flex-col tw-place-items-center tw-gap-5">
                    <div className="reveal-up tw-mt-5 tw-flex tw-flex-col tw-gap-3 tw-text-center">
                        <h2 className="reveal-up gradient-text tw-text-4xl tw-font-medium tw-text-gray-200 max-md:tw-text-2xl">
                            Your Ultimate<br /> Productivity Companion
                        </h2>
                    </div>
                    <div className="tw-mt-6 tw-flex tw-max-w-[80%] tw-flex-wrap tw-place-content-center tw-gap-8 max-lg:tw-flex-col">
                        <div className="reveal-up tw-flex tw-h-[200px] tw-w-[450px] tw-gap-8 tw-rounded-xl tw-border-[1px] tw-border-outlineColor tw-bg-secondary tw-p-8 max-md:tw-w-[320px]">
                            <div className="tw-text-4xl max-md:tw-text-2xl">
                            <div className="p-3 border border-1 rounded-full border-gray-500">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                    <path d="M19.5 13V9.36842C19.5 5.89491 19.5 4.15816 18.4749 3.07908C17.4497 2 15.7998 2 12.5 2H9.5C6.20017 2 4.55025 2 3.52513 3.07908C2.5 4.15816 2.5 5.89491 2.5 9.36842V14.6316C2.5 18.1051 2.5 19.8418 3.52513 20.9209C4.55025 22 6.20017 22 9.5 22H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.5 20C13.5 20 14.5 20 15.5 22C15.5 22 18.6765 17 21.5 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7 2L7.0822 2.4932C7.28174 3.69044 7.38151 4.28906 7.80113 4.64453C8.22075 5 8.82762 5 10.0414 5H11.9586C13.1724 5 13.7793 5 14.1989 4.64453C14.6185 4.28906 14.7183 3.69044 14.9178 2.4932L15 2" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                    <path d="M7 16H11M7 11H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </div>
                          
                            </div>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <h3 className="text-white tw-text-2xl max-md:tw-text-xl">
                                    Smart Task Management
                                </h3>
                                <p className="tw-text-gray-300 max-md:tw-text-sm">
                                    Manage tasks and set reminders effortlessly, ensuring you never miss a deadline.
                                </p>
                            </div>
                        </div>
                        <div className="reveal-up tw-flex tw-h-[200px] tw-w-[450px] tw-gap-8 tw-rounded-xl tw-border-[1px] tw-border-outlineColor tw-bg-secondary tw-p-8 max-md:tw-w-[320px]">
                            <div className="tw-text-4xl max-md:tw-text-2xl">
                            <div className="p-3 border border-1 rounded-full border-gray-500">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                <path d="M7.5 12H13.5M7.5 8H10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.5 20C9.55038 20.8697 10.8145 21.4238 12.2635 21.5188C13.4052 21.5937 14.5971 21.5936 15.7365 21.5188C16.1288 21.4931 16.5565 21.4007 16.9248 21.251C17.3345 21.0845 17.5395 21.0012 17.6437 21.0138C17.7478 21.0264 17.8989 21.1364 18.2011 21.3563C18.7339 21.744 19.4051 22.0225 20.4005 21.9986C20.9038 21.9865 21.1555 21.9804 21.2681 21.7909C21.3808 21.6013 21.2405 21.3389 20.9598 20.8141C20.5706 20.0862 20.324 19.2529 20.6977 18.5852C21.3413 17.6315 21.8879 16.5021 21.9678 15.2823C22.0107 14.6269 22.0107 13.9481 21.9678 13.2927C21.9146 12.4799 21.7173 11.7073 21.4012 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.345 17.4868C15.9006 17.2526 18.7328 14.4069 18.9658 10.8344C19.0114 10.1353 19.0114 9.41131 18.9658 8.71219C18.7328 5.13969 15.9006 2.29401 12.345 2.05985C11.132 1.97997 9.86553 1.98013 8.65499 2.05985C5.09943 2.29401 2.26725 5.13969 2.0342 8.71219C1.9886 9.41131 1.9886 10.1353 2.0342 10.8344C2.11908 12.1356 2.69992 13.3403 3.38372 14.3576C3.78076 15.0697 3.51873 15.9586 3.10518 16.735C2.807 17.2948 2.65791 17.5747 2.77762 17.7769C2.89732 17.9791 3.16472 17.9856 3.69951 17.9985C4.75712 18.024 5.47028 17.7269 6.03638 17.3134C6.35744 17.0788 6.51798 16.9615 6.62862 16.9481C6.73926 16.9346 6.957 17.0234 7.39241 17.2011C7.78374 17.3608 8.23812 17.4593 8.65499 17.4868C9.86553 17.5665 11.132 17.5666 12.345 17.4868Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            </svg>
                            </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <h3 className="text-white tw-text-2xl max-md:tw-text-xl">
                                    Voice and Text Integration
                                </h3>
                                <p className="tw-text-gray-300 max-md:tw-text-sm">
                                    Use voice commands or text to set reminders and manage tasks with ease.
                                </p>
                            </div>
                        </div>
                        <div className="reveal-up tw-flex tw-h-[200px] tw-w-[450px] tw-gap-8 tw-rounded-xl tw-border-[1px] tw-border-outlineColor tw-bg-secondary tw-p-8 max-md:tw-w-[320px]">
                            <div className="tw-text-4xl max-md:tw-text-2xl">
                                <div className="p-3 border border-1 rounded-full border-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                    <path d="M12 22C6.47715 22 2.00004 17.5228 2.00004 12C2.00004 6.47715 6.47719 2 12 2C16.4777 2 20.2257 4.94289 21.5 9H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 8V12L14 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M21.9551 13C21.9848 12.6709 22 12.3373 22 12M15 22C15.3416 21.8876 15.6753 21.7564 16 21.6078M20.7906 17C20.9835 16.6284 21.1555 16.2433 21.305 15.8462M18.1925 20.2292C18.5369 19.9441 18.8631 19.6358 19.1688 19.3065" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <h3 className="text-white tw-text-2xl max-md:tw-text-xl">
                                    Real-Time Productivity Boost
                                </h3>
                                <p className="tw-text-gray-300 max-md:tw-text-sm">
                                    Instantly boost productivity with quick reminders and seamless task tracking.
                                </p>
                            </div>
                        </div>
                        <div className="reveal-up tw-flex tw-h-[200px] tw-w-[450px] tw-gap-8 tw-rounded-xl tw-border-[1px] tw-border-outlineColor tw-bg-secondary tw-p-8 max-md:tw-w-[320px]">
                            <div className="tw-text-4xl max-md:tw-text-2xl">
                                <div className="p-3 border border-1 rounded-full border-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                    <path d="M13.9258 12.7775L11.7775 10.6292C11.4847 10.3364 11.3383 10.19 11.1803 10.1117C10.8798 9.96277 10.527 9.96277 10.2264 10.1117C10.0685 10.19 9.92207 10.3364 9.62923 10.6292C9.33638 10.9221 9.18996 11.0685 9.11169 11.2264C8.96277 11.527 8.96277 11.8798 9.11169 12.1803C9.18996 12.3383 9.33638 12.4847 9.62923 12.7775L11.7775 14.9258M13.9258 12.7775L20.3708 19.2225C20.6636 19.5153 20.81 19.6617 20.8883 19.8197C21.0372 20.1202 21.0372 20.473 20.8883 20.7736C20.81 20.9315 20.6636 21.0779 20.3708 21.3708C20.0779 21.6636 19.9315 21.81 19.7736 21.8883C19.473 22.0372 19.1202 22.0372 18.8197 21.8883C18.6617 21.81 18.5153 21.6636 18.2225 21.3708L11.7775 14.9258M13.9258 12.7775L11.7775 14.9258" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17 2L17.2948 2.7966C17.6813 3.84117 17.8746 4.36345 18.2556 4.74445C18.6366 5.12545 19.1588 5.31871 20.2034 5.70523L21 6L20.2034 6.29477C19.1588 6.68129 18.6366 6.87456 18.2556 7.25555C17.8746 7.63655 17.6813 8.15883 17.2948 9.2034L17 10L16.7052 9.2034C16.3187 8.15884 16.1254 7.63655 15.7444 7.25555C15.3634 6.87455 14.8412 6.68129 13.7966 6.29477L13 6L13.7966 5.70523C14.8412 5.31871 15.3634 5.12545 15.7444 4.74445C16.1254 4.36345 16.3187 3.84117 16.7052 2.7966L17 2Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                    <path d="M6 4L6.22108 4.59745C6.51097 5.38087 6.65592 5.77259 6.94167 6.05834C7.22741 6.34408 7.61913 6.48903 8.40255 6.77892L9 7L8.40255 7.22108C7.61913 7.51097 7.22741 7.65592 6.94166 7.94167C6.65592 8.22741 6.51097 8.61913 6.22108 9.40255L6 10L5.77892 9.40255C5.48903 8.61913 5.34408 8.22741 5.05833 7.94167C4.77259 7.65592 4.38087 7.51097 3.59745 7.22108L3 7L3.59745 6.77892C4.38087 6.48903 4.77259 6.34408 5.05833 6.05833C5.34408 5.77259 5.48903 5.38087 5.77892 4.59745L6 4Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                </svg>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <h3 className="text-white tw-text-2xl max-md:tw-text-xl">
                                    On-the-Go Convenience
                                </h3>
                                <p className="tw-text-gray-300 max-md:tw-text-sm">
                                    Stay organized anywhere with Echo AI, your personal assistant on the move.
                                </p>
                            </div>
                        </div>
                        <div className="reveal-up tw-flex tw-h-[200px] tw-w-[450px] tw-gap-8 tw-rounded-xl tw-border-[1px] tw-border-outlineColor tw-bg-secondary tw-p-8 max-md:tw-w-[320px]">
                            <div className="tw-text-4xl max-md:tw-text-2xl">
                                <div className="p-3 border border-1 rounded-full border-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                    <path d="M3 15.0614V6C5.1047 6.62133 9.57619 7.42671 15.0038 7.80281C17.9252 8.00525 19.3859 8.10646 20.1929 8.97688C21 9.8473 21 11.2497 21 14.0546V16.0683C21 18.9566 21 20.4008 20.0163 21.2998C19.0325 22.1987 17.6919 22.0677 15.0106 21.8058C13.7295 21.6806 12.3748 21.509 11 21.2775" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.6258 8C18.0035 6.57673 18.3453 3.98822 17.327 2.70292C16.6816 1.88827 15.7223 1.96654 14.7818 2.04926C9.83791 2.48406 6.34544 3.36731 4.39301 3.96737C3.55348 4.2254 3 5.04522 3 5.96044" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                    <path d="M15 15C15 15.8284 15.6716 16.5 16.5 16.5C17.3284 16.5 18 15.8284 18 15C18 14.1716 17.3284 13.5 16.5 13.5C15.6716 13.5 15 14.1716 15 15Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M3 19C3 19 4 19 5 21C5 21 8.17647 16 11 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <h3 className="text-white tw-text-2xl max-md:tw-text-xl">
                                    Financial Management
                                </h3>
                                <p className="tw-text-gray-300 max-md:tw-text-sm">
                                    Track finances and remember who owes you, all in one place.
                                </p>
                            </div>
                        </div>
                        <div className="reveal-up tw-flex tw-h-[200px] tw-w-[450px] tw-gap-8 tw-rounded-xl tw-border-[1px] tw-border-outlineColor tw-bg-secondary tw-p-8 max-md:tw-w-[320px]">
                            <div className="tw-text-4xl max-md:tw-text-2xl">
                                <div className="p-3 border border-1 rounded-full border-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                                    <path d="M6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M22 4C22 5.10457 21.1046 6 20 6C18.8954 6 18 5.10457 18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M22 20C22 21.1046 21.1046 22 20 22C18.8954 22 18 21.1046 18 20C18 18.8954 18.8954 18 20 18C21.1046 18 22 18.8954 22 20Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M6 20C6 21.1046 5.10457 22 4 22C2.89543 22 2 21.1046 2 20C2 18.8954 2.89543 18 4 18C5.10457 18 6 18.8954 6 20Z" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M20 6V18M18 20H6M18 4H6M4 6V18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5 9C16.5 8.53406 16.5 8.30109 16.4239 8.11732C16.3224 7.87229 16.1277 7.67761 15.8827 7.57612C15.6989 7.5 15.4659 7.5 15 7.5H9C8.53406 7.5 8.30109 7.5 8.11732 7.57612C7.87229 7.67761 7.67761 7.87229 7.57612 8.11732C7.5 8.30109 7.5 8.53406 7.5 9C7.5 9.46594 7.5 9.69891 7.57612 9.88268C7.67761 10.1277 7.87229 10.3224 8.11732 10.4239C8.30109 10.5 8.53406 10.5 9 10.5H15C15.4659 10.5 15.6989 10.5 15.8827 10.4239C16.1277 10.3224 16.3224 10.1277 16.4239 9.88268C16.5 9.69891 16.5 9.46594 16.5 9Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5 15C16.5 14.5341 16.5 14.3011 16.4239 14.1173C16.3224 13.8723 16.1277 13.6776 15.8827 13.5761C15.6989 13.5 15.4659 13.5 15 13.5H9C8.53406 13.5 8.30109 13.5 8.11732 13.5761C7.87229 13.6776 7.67761 13.8723 7.57612 14.1173C7.5 14.3011 7.5 14.5341 7.5 15C7.5 15.4659 7.5 15.6989 7.57612 15.8827C7.67761 16.1277 7.87229 16.3224 8.11732 16.4239C8.30109 16.5 8.53406 16.5 9 16.5H15C15.4659 16.5 15.6989 16.5 15.8827 16.4239C16.1277 16.3224 16.3224 16.1277 16.4239 15.8827C16.5 15.6989 16.5 15.4659 16.5 15Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <h3 className="text-white tw-text-2xl max-md:tw-text-xl">
                                    Simplified Organization
                                </h3>
                                <p className="tw-text-sm tw-text-gray-300">
                                    Keep life clutter-free with streamlined task and schedule management.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="qa" className="bg-black tw-flex tw-w-full tw-flex-col tw-place-content-center tw-place-items-center tw-gap-[10%] tw-p-[5%] tw-px-[10%]">
                <h3 className="reveal-up gradient-text tw-text-4xl tw-font-medium tw-text-gray-200 max-md:tw-text-2xl">
                    FAQ (Frequently Asked Question)
                </h3>
                <div className="tw-mt-5 tw-flex tw-min-h-[300px] tw-w-full tw-max-w-[850px] tw-flex-col tw-gap-4">
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span>What is Echo AI?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            Echo AI is your personal assistant app designed to help you manage tasks, set reminders, and stay organized. Whether it’s managing your schedule, keeping track of to-dos, or reminding you of important events, Echo AI is here to help.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span>How do I set a reminder in Echo AI?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            You can set a reminder by simply speaking or typing your request. For example, say or type, "Remind me to call Sarah tomorrow at 10 AM," and Echo AI will set the reminder for you.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span>
                                How does Echo AI help me stay organized?
                            </span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            Echo AI provides a central hub where you can see all your tasks, notes, reminders, and stored information at a glance. It helps you prioritize tasks and ensures you never miss a deadline or appointment.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span>Can I use Echo AI without typing?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            Absolutely! Echo AI supports voice commands, so you can interact with the app using just your voice. Simply speak your requests, and Echo AI will take care of the rest.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span> Can Echo AI help me track financial transactions?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            Yes, Echo AI can assist you with tracking financial transactions. You can record who owes you money, track payments, and set reminders for due dates.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span> What if I need to retrieve past information or tasks?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            You can easily retrieve past information using the memory retrieval feature. Simply ask Echo AI for details, such as "What was the date of my last meeting with John?" and Echo AI will provide the information.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span> Can I delete or update tasks and reminders?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            Yes, you can update or delete tasks and reminders at any time. Echo AI will confirm your request before making any changes, ensuring accuracy.
                        </div>
                    </div>
                    <div className="faq tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#1F2123] tw-bg-[#080808]">
                        <div onClick={toggleFaqAccordion} className="faq-accordion tw-flex tw-w-full tw-select-none tw-text-xl max-md:tw-text-lg">
                            <span> How much does Echo AI cost?</span>
                            <i className="bi bi-plus tw-ml-auto tw-font-semibold" />
                        </div>
                        <div className="content">
                            Echo AI offers a 7-day free trial. After the trial, you can choose from the following subscription plans:
                            $9.99 per month
                            $27.99 per quarter
                            $99.99 per year
                            These options allow you to select the plan that best fits your needs.
                        </div>
                    </div>
                </div>
                <div className="tw-mt-20 tw-flex tw-flex-col tw-place-items-center tw-gap-4">
                    <div className="text-white tw-text-3xl max-md:tw-text-2xl">
                        Still have questions?
                    </div>
                    <div className="text-gray-300 content">
                        If you need assistance, you can email us at echoai@doppelmate.com. We’re here to help with any questions or issues you might have.
                    </div>
                    <a href="mailto:echoai@doppelmate.com" className="btn !tw-rounded-full !tw-border-[1px] !tw-border-solid !tw-border-gray-300 !tw-bg-transparent tw-transition-colors tw-duration-[0.3s]">
                        Send Mail
                    </a>
                </div>
            </section>
            <section className="bg-black tw-flex tw-w-full tw-flex-col tw-place-content-center tw-place-items-center tw-gap-[10%] tw-p-[5%] tw-px-[10%] max-md:tw-px-2">
                <div className="signup-img-section tw-h-[550px] tw-flex max-lg:tw-flex-col tw-max-h-[550px] tw-max-w-[80%]  max-lg:tw-max-h-fit max-lg:tw-h-full max-lg:tw-max-w-full tw-overflow-hidden tw-rounded-xl tw-w-full">
                    <div className="tw-flex tw-flex-col tw-h-full tw-place-content-center tw-gap-3  tw-p-6 max-md:tw-max-w-full">
                        <div className="tw-flex tw-flex-col tw-gap-1">
                            <h2 className="tw-text-3xl tw-text-gray-300 max-md:tw-text-xl">
                                Join the elite few who will experience Echo AI before anyone else.
                            </h2>
                        </div>
                        <div className="tw-flex tw-h-[60px] tw-place-items-center tw-gap-2 tw-py-2 tw-overflow-hidden">
                            <input type="email" autoComplete="on" className="input tw-h-full tw-w-full !tw-border-gray-600 tw-p-2 tw-outline-none" placeholder="Your email" />
                            <button type="button" className="btn !tw-rounded-full !tw-border-[1px] !tw-border-solid !tw-border-gray-300 !tw-bg-transparent tw-transition-colors tw-duration-[0.3s]">
                                <i className="bi bi-chevron-right" />
                            </button>
                        </div>
                    </div>
                    <div className="tw-max-w-[60%] tw-w-full tw-h-full tw-flex max-lg:tw-max-w-full tw-place-content-end tw-place-items-end">
                        <img src="./assets/images/home/phone.png" alt="phone" className="tw-h-full tw-max-h-[90%] tw-object-contain" />
                    </div>
                </div>
            </section>
        </>
    )
}


