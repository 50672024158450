import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';

export function Privacy() {
    return(
        <>   
        <Header/>    
            <section className="py-10 bg-white sm:py-16 lg:py-24">
                <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Privacy Policy</h2>
                        <div className="flow-root mt-12 sm:mt-16">
                            <div className="divide-y divide-gray--200 -my-9">
                                <div className="py-9">
                                    <p className="text-xl font-semibold text-black">1. Introduction</p>
                                    <p className="mt-3 text-base text-gray-600">Welcome to Lemedo It Inc., DBA DoppelMate, Operating as Echo AI ("we," "us," or "our"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy describes how we collect, use, and share your information when you use our app and services or interact with us in any other way. By accessing or using our services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy. If you do not agree with our practices, please do not use our services.

</p>
                                </div>
                                <div className="py-9">
                                <p className="text-xl font-semibold text-black">2. Information We Collect</p>
                                <p className="mt-3 text-base text-gray-600">We may collect various types of information from and about you, including:</p>

                                <ul>
                                    <li>● Personal Information: Information that identifies you as an individual, such as your name, email address, mailing address, phone number, payment information, and any other information you provide directly to us.</li>
                                </ul>
                                <ul>
                                    <li>● Account Information: When you create an account, we collect information such as your username, password, and other registration details.</li>
                                </ul>
                                <ul>
                                    <li>● Usage Information: Information about how you use our services, including your IP address, browser type, operating system, pages visited, and the dates and times of your visits.</li>
                                </ul>
                                <ul>
                                    <li>● Device Information: Information about the device you use to access our services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.</li>
                                </ul>
                                <ul>
                                    <li>● Cookies and Tracking Technologies: We use cookies, beacons, tags, scripts, and other tracking technologies to collect and store information when you use our services. This helps us understand user activity and improve our services.</li>
                                </ul>

                                </div>

                                <div className="py-9">
                                    <p className="text-xl font-semibold text-black">3. How We Use Your Information</p>
                                        <ul>
                                            <li>● Providing and Managing Our Services: To deliver the services you request, manage your account, process transactions, and provide customer support.</li>
                                        </ul>
                                        <ul>
                                            <li>● Improving Our Services: To understand how our services are used, improve the content and functionality of our services, and develop new products and features.</li>
                                        </ul>
                                        <ul>
                                            <li>● Communicating with You: To send you important notices, such as changes to our terms, conditions, and policies, and to respond to your inquiries and support requests.</li>
                                        </ul>
                                        <ul>
                                            <li>● Personalizing Your Experience: To tailor the content and services we provide to better suit your preferences and interests.</li>
                                        </ul>
                                        <ul>
                                            <li>● Preventing Fraud and Ensuring Security: To protect our services, our users, and our business from fraud, unauthorized access, and other illegal activities.</li>
                                        </ul>
                                        <ul>
                                            <li>● Marketing and Advertising: With your consent, we may use your information to send you promotional materials and advertisements that may be of interest to you. You can opt-out of these communications at any time.</li>
                                        </ul>
                                    </div>

                                    <div className="py-9">
                                        <p className="text-xl font-semibold text-black">4. Sharing Your Information</p>
                                            <p className="mt-3 text-base text-gray-600">We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this Privacy Policy. We may share your information with:</p>
                                                <ul>
                                                    <li>● Service Providers: Third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                                                </ul>
                                                <ul>
                                                    <li>● Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</li>
                                                </ul>
                                                <ul>
                                                    <li>● Legal Obligations: To comply with legal obligations, including responding to subpoenas, court orders, and other legal processes.</li>
                                                </ul>
                                                <ul>
                                                    <li>● Protection of Rights: To enforce our terms and conditions, protect our rights, privacy, safety, or property, and that of our users and the public.</li>
                                                </ul>
                                    </div>

                                    <div className="py-9">
                                        <p className="text-xl font-semibold text-black">5. Data Security</p>
                                            <p className="mt-3 text-base text-gray-600">We implement reasonable administrative, technical, and physical security measures to protect your personal information from unauthorized access, use, alteration, and disclosure. However, no data transmission over the internet or electronic storage system is completely secure. While we strive to protect your information, we cannot guarantee its absolute security.</p>
                                    </div>

                                    <div className="py-9">
                                        <p className="text-xl font-semibold text-black">6. Your Rights and Choices</p>
                                            <p className="mt-3 text-base text-gray-600">You have certain rights and choices regarding your personal information, including:</p>
                                                <ul>
                                                    <li>● Access and Update: You have the right to access and update your personal information. You can do this by logging into your account or contacting us directly.</li>
                                                </ul>
                                                <ul>
                                                    <li>● Delete: You have the right to request that we delete your personal information, subject to certain legal exceptions.</li>
                                                </ul>
                                                <ul>
                                                    <li>● Opt-Out: You can opt-out of receiving marketing communications from us by following the unsubscribe instructions included in those communications or contacting us directly.</li>
                                                </ul>
                                                <ul>
                                                    <li>● Cookies and Tracking Technologies: You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. However, if you disable or refuse cookies, some parts of our services may be inaccessible or not function properly.</li>
                                                </ul>
                                    </div>

                                    <div className="py-9">
                                        <p className="text-xl font-semibold text-black">7. Children's Privacy</p>
                                            <p className="mt-3 text-base text-gray-600">Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information from our records.</p>
                                    </div>

                                    <div className="py-9">
                                        <p className="text-xl font-semibold text-black">8. International Data Transfers</p>
                                            <p className="mt-3 text-base text-gray-600">We may transfer your personal information to countries other than the country in which you reside. These countries may have data protection laws that are different from the laws of your country. We ensure that appropriate safeguards are in place to protect your information when it is transferred internationally.</p>
                                    </div>

                                    <div className="py-9">
                                        <p className="text-xl font-semibold text-black">9. Changes to This Privacy Policy</p>
                                            <p className="mt-3 text-base text-gray-600">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new Privacy Policy on our website. You are encouraged to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
                                    </div>

                                    <div className="py-9">
                                    <p className="text-xl font-semibold text-black">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                                    <p className="mt-3 text-base text-gray-600">
                                    Lemedo It Inc., DBA DoppelMate, Operating as Echo AI <br />
                                    2216 W Walnut Hill Ln <br />
                                    Suite 100 <br />
                                    Irving, TX 75038 <a href="mailto:support@doppelmate.com" title className="text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline">support@doppelmate.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section class="py-10 bg-white sm:pt-16 lg:pt-16">
                <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                    <hr class="mt-16 mb-10 border-gray-200" />

                    <div class="sm:flex sm:items-center sm:justify-between">
                        <p class="text-sm text-gray-600">© 2024 EchoAI, All Rights Reserved   |   EchoAI powered by LemeDo It! Inc</p>
                    </div>
                </div>
            </section>
        <Footer/>
        </>
    )
}